import jwtDecode from "jwt-decode";
import {
  AUTH_CHECK,
  AUTH_ERROR,
  AUTH_GET_PERMISSIONS,
  AUTH_LOGIN,
  AUTH_LOGOUT,
  CREATE,
  DELETE,
  DELETE_MANY,
  fetchUtils,
  GET_LIST,
  GET_MANY,
  GET_MANY_REFERENCE,
  GET_ONE,
  UPDATE,
  UPDATE_MANY,
} from "react-admin";
import * as tus from "tus-js-client";
import uuidv4 from "uuid/v4";
import { Actions, providerRef } from "../uploader/MediaUploadProvider";
import { authRoles } from "./authRoles";
import jsonServerProvider from "./ra-data-json-server";
import { authDisconnect } from "./socketIO";

export * from "./socketIO";
export { authRoles };

const {
  NODE_ENV,
  REACT_APP_API_BASE_URL,
  REACT_APP_LOGIN_URL,
  REACT_APP_MOCK_ROLES,
  REACT_APP_TUS_BUCKET_DAILY_DOSE: TUS_BUCKET_DAILY_DOSE = "daily-dose-prod",
  REACT_APP_TUS_PREFIX_DAILY_DOSE: TUS_PREFIX_DAILY_DOSE = "originals/",
  REACT_APP_TUS_ENDPOINT: TUS_ENDPOINT = "https://tusd.torahanytime.com/",
} = process.env;

const __DEV__ = NODE_ENV === "development";

export const baseURL = REACT_APP_API_BASE_URL.endsWith("/")
  ? REACT_APP_API_BASE_URL.substr(0, REACT_APP_API_BASE_URL.length - 1)
  : REACT_APP_API_BASE_URL;

/** Authorized user info. */
export const authUser = {
  deviceId: "",
  id: 0,
  email: undefined,
  loggedIn: false,
  roles: [],
  isAdministrator: false,
  isAuthor: false,
  isCnsclipper: false,
  isNewsletterAdmin: false,
  isSubscriber: false,
  isKiosk: false,
  isPartner: false,
  isStatViewer: false,
  isClipperAdmin: false,
  isCampaignAdmin: false,
  isDoseEmailAdmin: false,
  /**
   * Loads `authUser` from the given token, if any, and returns a success bool.
   * @param {string} [token] Optional token, otherwise loaded from localStorage.
   */
  load(token) {
    try {
      if (!token) {
        token = authToken();
      }
      if (!token) {
        return false;
      }
      /** @type {AuthTokenInfo} */
      const tokenInfo = jwtDecode(token);
      if (tokenInfo) {
        const roles =
          __DEV__ && REACT_APP_MOCK_ROLES
            ? REACT_APP_MOCK_ROLES.split(",").map((role) => role.trim())
            : tokenInfo.roles;
        authUser.id = tokenInfo.userId;
        authUser.email = tokenInfo.email;
        authUser.loggedIn = true;
        authUser.roles = roles;
        authRoles.forEach((ar) => {
          authUser[ar.prop] = roles.indexOf(ar.id) > -1;
        });
        authUser.deviceId = getOrCreateDeviceUUID();
        return true;
      }
      return false;
    } catch (err) {
      console.error(err);
      return false;
    }
  },
  onLogin(handler) {
    authEventSubscribers.add("login", handler);
    return () => {
      authEventSubscribers.remove("login", handler);
    };
  },
  onLogout(handler) {
    authEventSubscribers.add("logout", handler);
    return () => {
      authEventSubscribers.remove("logout", handler);
    };
  },
  /**
   * Resets all `authUser` props.
   */
  reset() {
    authUser.id = 0;
    authUser.email = undefined;
    authUser.loggedIn = false;
    authUser.roles = [];
    authRoles.forEach((ar) => (authUser[ar.prop] = false));
    authUser.deviceId = "";
  },
};

/** @param {string} relativeURL */
export function apiURL(relativeURL) {
  if (relativeURL.startsWith("/")) {
    return baseURL + relativeURL;
  }
  return baseURL + "/" + relativeURL;
}

/** An axios compatible fetch client using `authFetchJson`. */
export const authClient = {
  /** HTTP Get
   * @param {string} url Relative url to an API endpoint.
   * @param {RequestInit} [options] Request options.
   */
  get(url, options) {
    return authFetchJson(apiURL(url), { method: "GET", ...options });
  },
  /** HTTP Delete
   * @param {string} url Relative url to an API endpoint.
   * @param {RequestInit} [options] Request options.
   */
  delete(url, options) {
    return authFetchJson(apiURL(url), { method: "DELETE", ...options });
  },
  /** HTTP Post
   * @param {string} url Relative url to an API endpoint.
   * @param {BodyInit} [data]
   * @param {RequestInit} [options] Request options.
   */
  post(url, data, options) {
    return authFetchJson(apiURL(url), {
      method: "POST",
      body: JSON.stringify(data),
      ...options,
    });
  },
  /** HTTP Patch
   * @param {string} url Relative url to an API endpoint.
   * @param {BodyInit} [data]
   * @param {RequestInit} [options] Request options.
   */
  patch(url, data, options) {
    return authFetchJson(apiURL(url), {
      method: "PATCH",
      body: JSON.stringify(data),
      ...options,
    });
  },
  /** HTTP Put
   * @param {string} url Relative url to an API endpoint.
   * @param {BodyInit} [data]
   * @param {RequestInit} [options] Request options.
   */
  put(url, data, options) {
    return authFetchJson(apiURL(url), {
      method: "PUT",
      body: JSON.stringify(data),
      ...options,
    });
  },
  putUnstringifiedData(url, data, options) {
    return authFetchJson(apiURL(url), {
      method: "PUT",
      body: data,
      ...options,
    });
  },
};

const authEventSubscribers = {
  login: [],
  logout: [],

  add(type, sub) {
    const { [type]: subscribers } = authEventSubscribers;
    subscribers.push(sub);
  },
  notify(type, ...args) {
    const { [type]: subscribers } = authEventSubscribers;
    subscribers.forEach((sub) => sub(...args));
  },
  remove(type, sub) {
    const { [type]: subscribers } = authEventSubscribers;
    authEventSubscribers[type] = subscribers.filter((item) => item !== sub);
  },
};
/** Adds an authorization header to react-admins `fetchUtils.fetchJson`.
 * @param {string} url Relative url to an API endpoint.
 * @param {RequestInit} [options] Request options.
 * @returns {Promise<FetchJsonResponse>}
 */
export function authFetchJson(url, options = {}) {
  const token = authToken();
  if (token) {
    const bearerToken = `Bearer ${token}`;
    if (options.headers) {
      options.headers.set("Authorization", bearerToken);
    } else {
      options.headers = new Headers({
        Accept: "application/json",
        Authorization: bearerToken,
      });
    }
  }
  return fetchUtils.fetchJson(url, options);
}
/** Handlers for different types of react-admin AUTH actions.
 * @type {{[action:string]: (params:object)=> Promise<any>}}
 */
const authHandlers = {
  [AUTH_CHECK](params) {
    if (!authUser.loggedIn && !authUser.load()) {
      return Promise.reject();
    }
    return Promise.resolve();
  },
  [AUTH_ERROR](params) {
    const status = params.status;
    if (status === 401 || status === 403) {
      localStorage.removeItem("token");
      localStorage.removeItem("jwtExpiry");
      authUser.reset();
      return Promise.reject();
    }
    return Promise.resolve();
  },
  /** Function to provide user permissions when rendering resources with
   * `<Admin>{(permissions) => [<Resource ... />]}</Admin>`.
   * See https://marmelab.com/react-admin/Authorization.html
   * See App.renderResources where the result of this function is passed.
   */
  [AUTH_GET_PERMISSIONS](params) {
    if (!authUser.loggedIn) {
      return Promise.reject();
    }
    return Promise.resolve(authUser.roles);
  },
  [AUTH_LOGIN](params) {
    const { username, password } = params;
    const request = new Request(REACT_APP_LOGIN_URL, {
      method: "POST",
      body: JSON.stringify({
        email: username,
        password,
      }),
      headers: new Headers({
        "Content-Type": "application/json",
      }),
    });
    return fetch(request)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then(({ token, expiration }) => {
        localStorage.setItem("token", token);
        localStorage.setItem("jwtExpiry", expiration);
        authUser.load(token);
        authEventSubscribers.notify("login");
        // NOTE: Subscribing to the login auth event may work for some cases,
        // but completely reloading the page after a login is the safest way
        // to ensure that the application uses current roles and permissions...
        setTimeout(() => {
          window.location.reload(true);
        }, 1000);
      });
  },
  [AUTH_LOGOUT](params) {
    localStorage.removeItem("token");
    localStorage.removeItem("jwtExpiry");
    authUser.reset();
    authEventSubscribers.notify("logout");
    authDisconnect();
    return Promise.resolve();
  },
};
/** React-admin authorization provider */
export function authProvider(type, params) {
  const handler = authHandlers[type];
  if (handler) {
    return handler(params);
  }
  return Promise.reject("Unknown method");
}
/** Returns `true` if the user is a full `administrator` or has one of the
 * given roles.
 * @param {string[]} [roles] The roles to check for.
 * @param {{[role:string]:string[]}} [permissions] Optional permissions by role.
 * @param {"create"|"edit"|"list"} [permission] Permission to check for.
 */
export function authorized(roles, permissions, permission) {
  if (!authUser.loggedIn) {
    return false;
  }
  if (authUser.isAdministrator) {
    return true;
  }
  if (roles) {
    const { roles: userRoles } = authUser;
    if (userRoles) {
      if (
        roles.filter(
          (r) =>
            // Are we authorized in the requested role?
            userRoles.indexOf(r) > -1 &&
            // If a specific permission was requested and specific permissions
            // were declared on the resource, does the role have permission?
            (!permission ||
              !permissions ||
              (permissions[r] && permissions[r].indexOf(permission) > -1)),
        ).length > 0
      ) {
        return true;
      }
    }
  }
  return false;
}
/** Returns the auth token, if any. */
export function authToken() {
  return localStorage.getItem("token");
}

const DATA_URL_PREFIX_ENDING = "base64,";
const DATA_URL_PREFIX_ENDING_LENGTH = DATA_URL_PREFIX_ENDING.length;

const jsonRequest = jsonServerProvider(apiURL("/admin"), authFetchJson);

/** Converts an inputted `File` to a data url string.
 * @param {File} file
 * @returns {Promise<string | ArrayBuffer>}
 */
export function convertFileToDataURL(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });
}
/** Converts an inputted `File` to a base64 string.
 * @param {File} file
 * @returns {Promise<string | ArrayBuffer>}
 */
export async function convertFileToBase64(file) {
  const dataURL = await convertFileToDataURL(file);
  const base64 = dataURL.substr(
    dataURL.indexOf(DATA_URL_PREFIX_ENDING) + DATA_URL_PREFIX_ENDING_LENGTH,
  );
  // console.log(`Converted file ${file.name} to base64:`, base64);
  return base64;
}
/**
 * Converts the given data objects `FILE_*` keys (if any) to upload.
 */
export async function convertFilesToUpload(data) {
  let dataOut;
  const keys = Object.keys(data);
  const keysLen = keys.length;
  for (let i = 0; i < keysLen; i++) {
    const key = keys[i];
    // Only process `params.data` fields that hold files.
    if (!key.startsWith("FILE_")) {
      continue;
    }
    // Initialize `dataOut` and prepare to convert the `fileProp` field.
    if (!dataOut) {
      dataOut = {
        ...data,
      };
    }
    const fileProp = data[key];
    if (!fileProp) {
      // Cleanup and skip.
      delete dataOut[key];
      continue;
    }
    if (Array.isArray(fileProp)) {
      // Multiple files field.
      const converted = await convertMultipleFilesToUpload(fileProp);
      if (converted.length < 1) {
        delete dataOut[key];
      } else {
        dataOut[key] = converted;
      }
    } else {
      // Single file field.
      if (!fileProp.rawFile) {
        // Cleanup and skip.
        delete dataOut[key];
      } else {
        dataOut[key] = await convertFileToUpload(fileProp);
      }
    }
  }
  return dataOut || data;
}
/**
 * Converts a single file prop for upload.
 * @param {{rawFile:File}} fileProp
 */
async function convertFileToUpload(fileProp) {
  /** @type {File} */
  const rawFile = fileProp.rawFile;
  const base64 = await convertFileToBase64(rawFile);
  return {
    name: rawFile.name,
    size: rawFile.size,
    type: rawFile.type,
    data: base64,
  };
}
/**
 * Converts an array of file props for upload.
 * @param {{rawFile:File}[]} filesProp
 */
async function convertMultipleFilesToUpload(filesProp) {
  const { length } = filesProp;
  /** @type {{name:string,size:number,type:string,data:string}[]} */
  const converted = [];
  for (let i = 0; i < length; i++) {
    const fileProp = filesProp[i];
    /** @type {File} */
    const rawFile = fileProp.rawFile;
    if (!rawFile) {
      continue;
    }
    const base64 = await convertFileToBase64(rawFile);
    converted.push({
      name: rawFile.name,
      size: rawFile.size,
      type: rawFile.type,
      data: base64,
    });
  }
  return converted;
}

export async function convertFileToTusUploadResults(tusEndpoint, file) {
  const uploadFinished = new Promise((resolve) => {
    const upload = new tus.Upload(file, {
      // Endpoint is the upload creation URL from your tus server
      endpoint: tusEndpoint,
      // Retry delays will enable tus-js-client to automatically retry on errors
      retryDelays: [0, 3000, 5000, 10000, 20000],
      // Attach additional meta data about the file for the server
      metadata: {
        filename: file.name,
        filetype: file.type,
      },
      // Callback for errors which cannot be fixed using retries
      onError: function (error) {
        console.log("Tus Upload failed because: " + error);
      },
      // Callback for reporting upload progress
      onProgress: function (bytesUploaded, bytesTotal) {
        var percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2);
        console.log(bytesUploaded, bytesTotal, percentage + "%");
      },
      // Callback for once the upload is completed
      onSuccess: function () {
        console.log("Download %s from %s", upload.file.name, upload.url);
        resolve(upload);
      },
    });

    // Check if there are any previous uploads to continue.
    upload.findPreviousUploads().then(function (previousUploads) {
      // Found previous uploads so we select the first one.
      if (previousUploads.length) {
        upload.resumeFromPreviousUpload(previousUploads[0]);
      }

      // Start the upload
      upload.start();
    });
  });

  const upload = await uploadFinished;

  return {
    bucket: TUS_BUCKET_DAILY_DOSE,
    prefix: TUS_PREFIX_DAILY_DOSE,
    /** Example: From `https://tusd.torahanytime.com/8d9e7d72d525cb5cb607b7d9f274238a+2~tQmI2C9TxIOw361bEx9tSlPtbNIECy5`, we want `8d9e7d72d525cb5cb607b7d9f274238a` */
    filename: upload.url.split("/").pop().split("+")[0],
    downloadUrl: upload.url,
  };
}
/** Gets or creates a local device UUID from localStorage. */
function getOrCreateDeviceUUID() {
  const KEY = "device_uuid";
  let deviceId = localStorage.getItem(KEY);
  if (!deviceId) {
    deviceId = uuidv4();
    localStorage.setItem(KEY, deviceId);
  }
  return deviceId;
}

/** Returns `params` by reference, except with possibly modified `params.data` if it contains a key starting with `FILE_`. */
async function withBase64FileUploads(params) {
  params.data = await convertFilesToUpload(params.data);
  return params;
}

/** Returns `params` by reference, except with possibly modified `params.data` if it contains a key `FILE_original_video`. */
async function withTusFileUploads(params) {
  if (params.data.FILE_original_video) {
    params.data.FILEINFO_original_video = await convertFileToTusUploadResults(
      TUS_ENDPOINT,
      params.data.FILE_original_video.rawFile,
    );
    delete params.data.FILE_original_video;
  }
  return params;
}

/** Format: `customDataMethods[resource][methodType]`, where `resource` is e.g. `lecture`, and `methodType` is e.g. `getOne` */
const customDataMethods = {
  lectures: {
    /** This mess of a function was pieced-together from the `dataActionHandlers` functionality in the legacy DataProvider */
    create: async (params) => {
      const type = CREATE,
        resource = "lectures";

      // NOTE: `result` will be the submitted params with a new `data.id` value,
      // not the entire new record from the database.
      const result = await jsonRequest(type, resource, params);
      const {
        data: { id },
      } = result;
      // console.log("CREATED LECTURE: ", id, result);
      if (providerRef.current) {
        providerRef.current.postMessage({
          action: Actions.saved_lecture,
          lectureId: id,
        });
      }
    },
  },
  daily_dose: {
    create: async (params) =>
      jsonRequest(CREATE, "daily_dose", await withBase64FileUploads(params)),
  },
};

const resourceGetList = (resource) =>
  customDataMethods[resource]?.getList
    ? customDataMethods[resource]?.getList
    : (params) => jsonRequest(GET_LIST, resource, params);
const resourceGetOne = (resource) =>
  customDataMethods[resource]?.getOne
    ? customDataMethods[resource]?.getOne
    : (params) => jsonRequest(GET_ONE, resource, params);
//
const resourceGetMany = (resource) =>
  customDataMethods[resource]?.getMany
    ? customDataMethods[resource]?.getMany
    : (params) => jsonRequest(GET_MANY, resource, params);
//
const resourceGetManyReference = (resource) =>
  customDataMethods[resource]?.getManyReference
    ? customDataMethods[resource]?.getManyReference
    : (params) => jsonRequest(GET_MANY_REFERENCE, resource, params);
//
const resourceCreate = (resource) =>
  customDataMethods[resource]?.create
    ? customDataMethods[resource]?.create
    : async (params) =>
        jsonRequest(CREATE, resource, await withBase64FileUploads(params));
//
const resourceUpdate = (resource) =>
  customDataMethods[resource]?.update
    ? customDataMethods[resource]?.update
    : async (params) =>
        jsonRequest(UPDATE, resource, await withBase64FileUploads(params));
//
const resourceUpdateMany = (resource) =>
  customDataMethods[resource]?.updateMany
    ? customDataMethods[resource]?.updateMany
    : (params) => jsonRequest(UPDATE_MANY, resource, params);
//
const resourceDelete = (resource) =>
  customDataMethods[resource]?.delete
    ? customDataMethods[resource]?.delete
    : (params) => jsonRequest(DELETE, resource, params);
//
const resourceDeleteMany = (resource) =>
  customDataMethods[resource]?.deleteMany
    ? customDataMethods[resource]?.deleteMany
    : (params) => jsonRequest(DELETE_MANY, resource, params);

/**
 * Custom JSON data provider. Wraps `ra-data-json-server` with additional
 * functionality such as `File` uploads.
 *
 * In the case of File uploads, the file is in `params.data` like any other
 * field, under the `rawFile` property. This DataProvider finds fields that
 * begin with `FILE_`, and replaces it with an object which is what's submitted
 * to the DataProvider's configured endpoint (e.g. `POST
 * https://api.torahanytime.com/admin/daily_dose`).
 *
 * @param {string} type `CREATE`, `UPDATE`, etc.
 * @param {string} resource Such as `lectures` or `daily_doses`.
 */
export const rootProvider = {
  /** get a list of records based on sort, filter, and pagination */
  getList: async (resource, params) => resourceGetList(resource)(params),
  /** get a single record by id */
  getOne: async (resource, params) => resourceGetOne(resource)(params),
  /** get a list of records based on an array of ids*/
  getMany: async (resource, params) => resourceGetMany(resource)(params),
  /** get the records referenced to another record, e.g. comments for a post */
  getManyReference: async (resource, params) =>
    resourceGetManyReference(resource)(params),
  /** create a record */
  create: async (resource, params) => resourceCreate(resource)(params),
  /** update a record based on a patch */
  update: async (resource, params) => resourceUpdate(resource)(params),
  /** update a list of records based on an array of ids and a common patch */
  updateMany: async (resource, params) => resourceUpdateMany(resource)(params),
  /** delete a record by id */
  delete: async (resource, params) => resourceDelete(resource)(params),
  /** delete a list of records based on an array of ids */
  deleteMany: async (resource, params) => resourceDeleteMany(resource)(params),
};

// Try to load the user information immediately if the token exists.
authUser.load();

// #region Typedefs
/** @typedef {"GET_LIST" | "GET_ONE" | "GET_MANY" | "GET_MANY_REFERENCE" | "CREATE" | "UPDATE" | "UPDATE_MANY" | "DELETE" | "DELETE_MANY"} DataActions */
/**
 * @typedef {(type:string,resource:string,params:any,submit:(type:string,resource:string,params:any)=>Promise)=>Promise} DataActionHandler
 */
/**
 * @typedef {object} AuthTokenInfo
 * @property {any} claims
 * @property {number} expiration
 * @property {number} iat
 * @property {boolean} loggedIn
 * @property {string[]} roles
 * @property {number|string} userId
 */
/**
 * @typedef {object} FetchJsonResponse
 * @property {number} status HTTP status code of the response.
 * @property {Headers} headers Standard Headers object for the response.
 * @property {string} body Text of the response body.
 * @property {object} [json] JSON parsed from the body text, if any.
 */
// #endregion
