import { FastForward } from "lucide-react";
import {
  Create,
  Datagrid,
  Edit,
  List,
  SimpleForm,
  TextField,
  TextInput,
} from "react-admin";
import {
  CardListActions,
  DefaultBulkActionButtons,
  EditorToolbar,
  MorePagination,
  validation,
} from "../../../components";
import SanitizedP from "../../../components/field/SanitizedP";

const validate = {
  required: [validation.required()],
};

function CreateView(props) {
  return (
    <Create {...props} redirect="edit" title="Upload Clip">
      <SimpleForm toolbar={<EditorToolbar />}>
        <SanitizedP>
          TorahAnytime URL ending goes here. Only enter from "/". Do not include
          torahanytime.com (Ex. /live-event)
        </SanitizedP>
        <TextInput
          fullWidth
          source="original_url"
          label="TorahAnytime Url"
          validate={validate.required}
        />
        <SanitizedP>
          Enter the FULL URL to redirect users to (Ex.
          https://google.com/yadada)
        </SanitizedP>
        <TextInput
          fullWidth
          source="redirect_url"
          label="Redirect Url"
          validate={validate.required}
        />
      </SimpleForm>
    </Create>
  );
}

function EditView(props) {
  return (
    <Edit {...props} redirect={false} undoable={false}>
      <SimpleForm toolbar={<EditorToolbar showDelete />}>
        <TextField source="id" />
        <SanitizedP>
          TorahAnytime URL ending goes here. Only enter from "/". Do not include
          torahanytime.com (Ex. /live-event)
        </SanitizedP>
        <TextInput
          fullWidth
          source="original_url"
          label="Original Url"
          validate={validate.required}
        />
        <SanitizedP>
          Enter the FULL URL to redirect users to (Ex.
          https://google.com/yadada)
        </SanitizedP>
        <TextInput
          fullWidth
          source="redirect_url"
          label="Redirect Url"
          validate={validate.required}
        />
      </SimpleForm>
    </Edit>
  );
}

function ListView(props) {
  return (
    <List
      {...props}
      actions={<CardListActions exporter={true} />}
      filters={[
        <TextInput source="original_url" alwaysOn />,
        <TextInput source="redirect_url" alwaysOn />,
      ]}
      perPage={50}
      pagination={<MorePagination />}
      sort={{
        field: "created_at",
        order: "DESC",
      }}
      title="Redirects"
    >
      <Datagrid
        rowClick="edit"
        bulkActionButtons={<DefaultBulkActionButtons />}
      >
        <TextField source="id" />
        <TextField source="original_url" label="Original Url" />
        <TextField source="redirect_url" label="Redirect Url" />
      </Datagrid>
    </List>
  );
}

export const Redirects = {
  category: "manage",
  name: "manage-redirects",
  list: ListView,
  create: CreateView,
  icon: FastForward,
  options: { label: "Redirects" },
  edit: EditView,
};
