import { Filter, ScissorsSquare } from "lucide-react";
import { Launch, Undo, CopyAll } from "@mui/icons-material";
import {
  Button as MuiButton,
  IconButton,
  ButtonGroup,
  Switch,
  Paper,
} from "@mui/material";
import {
  BooleanInput,
  Button,
  Create,
  Datagrid,
  Edit,
  EditButton,
  FormTab,
  FunctionField,
  List,
  ReferenceField,
  SimpleForm,
  TabbedForm,
  TextField,
  TextInput,
  SelectInput,
  useRecordContext,
  useUpdate,
  useListContext,
  ReferenceInput,
  AutocompleteInput,
  useCreate,
  useDataProvider,
  useNotify,
  NumberInput,
  WithListContext,
  SelectArrayInput,
  DateInput,
} from "react-admin";
import { Link } from "react-router-dom";
import {
  CardListActions,
  CategoryInput,
  EditorToolbar,
  MorePagination,
  SpeakerInput,
  SubCategoryInput,
  validation,
} from "../../components";
import DateTimeField from "../../components/field/DateTimeField";
import { useCallback, useState, useEffect } from "react";
import { authClient, authUser } from "../../server";
import { TemporalProgressField } from "../../components/field/TemporalProgressField";
import {
  getDurationStringShort,
  durationStringToSeconds,
} from "../../components/utils";
import { CopyToClipboard } from "../../components";

const validate = {
  title: [validation.required(), validation.minLength(2)],
  email: [validation.required()],
  lectureUrl: [validation.required()],
  location: [validation.required()],
  clipLink: [validation.required()],
  category: [validation.required()],
  date_recorded: [validation.required()],
  language: [validation.required()],
  speaker: [validation.required()],
};

function CreateView(props) {
  return (
    <Create {...props} redirect="edit" title="Upload Clip">
      <SimpleForm toolbar={<EditorToolbar />}>
        {/* <TextInput
          source="clipper_email"
          label="Clipper Email"
          validate={validate.email}
        />
        <TextInput
          source="lecture_url"
          label="Lecture URL or Id"
          validate={validate.lectureUrl}
        />
        <TextInput
          source="clip_title"
          label="Clip Title"
          validate={validate.title}
        />
        <InputRow validate={validate.category}>
          <TextInput source="cat_id" label="Category Id" />
          <span>or choose:</span>
          <CategoryInput source="cat_id" allowEmpty />
        </InputRow>
        <InputRow>
          <TextInput source="sub_cat_id" label="Sub-Category Id" />
          <span>or choose:</span>
          <SubCategoryInput source="sub_cat_id" />
        </InputRow>
        <TextInput
          source="clip_link"
          label="Clip Link"
          validate={validate.clipLink}
        /> */}
        <div>
          "Create" form not currently available. Please create a Clip by using
          the App or Website, as the interface is much better suited for it.
        </div>
      </SimpleForm>
    </Create>
  );
}

const EditActions = () => (
  <CardListActions>
    <Button
      color="primary"
      onClick={() => window.alert("OK")}
      label="Export Emails"
      title="Exports the emails of all followers..."
    />
  </CardListActions>
);

function EditView(props) {
  return (
    <Edit
      {...props}
      redirect={false}
      undoable={false}
      actions={<EditActions />}
    >
      <TabbedForm toolbar={<EditorToolbar />}>
        <FormTab label="Clip Details" replace>
          <TextInput source="name" label="Title" />
          <ClonedFromClipField />
          {/* <TextField source="ending" /> */}
          <SelectInput
            source="type"
            label="Worthiness"
            choices={[
              { id: "tat_clip", name: "TAT Clip" },
              { id: "dose", name: "Daily Dose" },
              { id: "pending", name: "Pending" },
              { id: "standard", name: "Standard" },
            ]}
          />
          <PublishingStatusOrActionsField />
          <CategoryInput source="category" />
          <SubCategoryInput source="subcategory" />
          <TextInput
            source="time_in"
            label="Start Time"
            /* DB value to string for UI */
            format={(timeIn) => getDurationStringShort(Number(timeIn))}
            /* raw string to DB */
            parse={durationStringToSeconds}
          />
          <TextInput
            source="duration"
            label="Duration"
            format={(duration) => getDurationStringShort(Number(duration))}
            parse={durationStringToSeconds}
          />
          <TextInput source="ending" label="Ending Words" multiline />
          <TextInput source="notes" label="Notes" multiline />
          <DownloadMp4Button />
        </FormTab>
      </TabbedForm>
    </Edit>
  );
}

function PublishingStatusOrActionsField() {
  const clip = useRecordContext();
  if (clip.tat_clip_lecture_id) {
    return (
      <ReferenceField
        source="tat_clip_lecture_id"
        label="Clip Lecture"
        reference="lectures"
      />
    );
  }
  if (clip.daily_dose_id) {
    return (
      <ReferenceField
        source="daily_dose_id"
        label="Dose"
        reference="daily_dose"
      />
    );
  }
  if (clip.type === "tat_clip") {
    return (
      <MuiButton
        variant="outlined"
        onClick={() => {
          alert("Soon to be Implemented");
        }}
      >
        Publish Clip
      </MuiButton>
    );
  }
  if (clip.type === "dose") {
    return <PublishDoseButton clip={clip} />;
  }
  return <span>-</span>;
}

function PublishDoseButton({ clip }) {
  const [create, { isLoading, error }] = useCreate();
  const [update, { isLoading2, error2 }] = useUpdate();

  const handleClick = async () => {
    try {
      // await update("clips_new", {id: clip.id, data: {daily_dose_id: true}});
      await create(
        "daily_dose",
        {
          data: {
            lecture_id: clip.lecture_id,
            title: clip.name,
            clip_id: clip.id,
          },
        },
        {
          onSuccess: ({ id: dailyDoseId }) => {
            update("clips_new", {
              id: clip.id,
              data: { daily_dose_id: dailyDoseId },
            }).then(() => {
              alert("Daily Dose created successfully!");
            });
          },
        },
      );
    } catch (error) {
      console.error("Error creating daily dose:", error);
      alert("Error creating daily dose.");
    }
  };

  return (
    <MuiButton variant="outlined" onClick={handleClick} disabled={isLoading}>
      Publish Dose
    </MuiButton>
  );
}

function ClonedFromClipField() {
  const clip = useRecordContext();
  const dataProvider = useDataProvider();
  const [clonedFromClip, setClonedFromClip] = useState(null);
  useEffect(() => {
    if (clip.source_clip_id) {
      dataProvider
        .getOne("clips_new", { id: clip.source_clip_id })
        .then((response) => {
          setClonedFromClip(response.data);
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      setClonedFromClip(null);
    }
  }, [clip.source_clip_id, dataProvider]);

  return (
    clonedFromClip && (
      <Link to={`/clips_new/${clonedFromClip.id}`} target="_blank">
        Cloned From Clip
      </Link>
    )
  );
}

function WorthinessOrActionsField() {
  const clip = useRecordContext();
  const [update, { isPending, error }] = useUpdate();

  function handleMakeDoseClick() {
    // update("clips_new", {
    //   id: clip.id,
    //   data: { has_potential: true },
    //   previousData: clip,
    // });
    update("clips_new", {
      id: clip.id,
      data: { type: "dose" },
      previousData: clip,
    });
  }
  function handleMakeClipClick() {
    update("clips_new", {
      id: clip.id,
      data: { type: "tat_clip" },
      previousData: clip,
    });
  }
  function handleStandardClick() {
    update("clips_new", {
      id: clip.id,
      data: { type: "standard" },
      previousData: clip,
    });
  }
  if (clip.type === "tat_clip" || clip.type === "dose") {
    return (
      <div>
        <TextField source="type" label="Worthiness" />
        <IconButton
          variant="contained"
          color="error"
          size="small"
          onClick={handleStandardClick}
        >
          <Undo />
        </IconButton>
      </div>
    );
  }
  if (clip.type === "standard") {
    return (
      <ButtonGroup variant="contained" aria-label="Basic button group">
        <MuiButton onClick={handleMakeDoseClick}>Dose</MuiButton>
        <MuiButton onClick={handleMakeClipClick}>Clip</MuiButton>
      </ButtonGroup>
    );
  }
  return <span>-</span>;
}

function ReviewedSwitch() {
  const clip = useRecordContext();
  const [update, { isPending, error }] = useUpdate();

  function handleToggle() {
    update("clips_new", {
      id: clip.id,
      data: { is_reviewed: !clip.is_reviewed },
      previousData: clip,
    });
  }

  return <Switch checked={clip.is_reviewed} onChange={handleToggle} />;
}

function HasPotentialSwitch() {
  const clip = useRecordContext();
  const [update, { isPending, error }] = useUpdate();

  function handleToggle() {
    update("clips_new", {
      id: clip.id,
      data: { has_potential: !clip.has_potential },
      previousData: clip,
    });
  }

  return <Switch checked={clip.has_potential} onChange={handleToggle} />;
}

function DownloadMp4Button() {
  const clip = useRecordContext();
  const dataProvider = useDataProvider();
  const [isLoading, setIsLoading] = useState(false);
  const [url, setUrl] = useState("");
  const [speaker, setSpeaker] = useState(null);

  const handleClick = useCallback(() => {
    setIsLoading(true);
    authClient.post("/admin/clips_new/kickoff_temporary_link_to_mp4", {
      id: clip.id,
    });
  }, [clip.id]);

  useEffect(() => {
    dataProvider
      .getOne("speakers", { id: clip.speaker_id })
      .then((response) => {
        setSpeaker(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [dataProvider, clip.speaker_id]);

  useEffect(() => {
    authClient
      .get(`/admin/clips_new/query_temporary_link_to_mp4/${clip.id}`)
      .then((response) => {
        // if the workflow doesn't even exist, do nothing:
        if (response.json.url === null) {
          return;
        }
        // if the workflow exists, but has no url because it's running, poll:
        if (response.json.url === "" && response.json.status !== "COMPLETED") {
          setIsLoading(true);
          return;
        }
        // if the workflow exists, but has no url because the file was evicted (remember, these mp4s are tmeporary), do nothing:
        if (response.json.url === "" && response.json.status === "COMPLETED") {
          return;
        }
        // if the workflow exists and has a url, set it:
        setIsLoading(false);
        setUrl(response.json.url);
      });
  }, [clip.id]);

  useEffect(() => {
    if (isLoading) {
      const intervalId = setInterval(async () => {
        const response = await authClient.get(
          `/admin/clips_new/query_temporary_link_to_mp4/${clip.id}`,
        );
        if (response.json.url !== null && response.json.url !== "") {
          setIsLoading(false);
          setUrl(response.json.url);
        }
      }, 1000);
      return () => {
        clearInterval(intervalId);
      };
    }
  }, [isLoading, clip.id]);

  if (isLoading) {
    return (
      <div>
        <span>Loading...</span>
        <TemporalProgressField
          workflowId={`TemporaryVideoClip-${clip.id}`}
          activityNames={["downloadFileById"]}
          pollInterval={1000}
        />
      </div>
    );
  }
  if (url === "") {
    return (
      <MuiButton variant="outlined" onClick={handleClick}>
        Get URL
      </MuiButton>
    );
  }
  return (
    <>
      <a target="_blank" href={url} rel="noopener noreferrer">
        Download
      </a>
      <CopyToClipboard
        textToCopy={`${clip?.name}

${clip?.ending}

${speaker?.name}

${url}

MyTAT.me/s${clip?.speaker_id}


${clip?.notes || ""}
`}
      />
    </>
  );
}

const CloneField = () => {
  const clip = useRecordContext();
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const handleCloneClick = async () => {
    const newClip = await dataProvider.create("clips_new", {
      data: {
        lecture_id: clip.lecture_id,
        name: `(Clone) ${clip.name}`,
        ending: clip.ending,
        notes: clip.notes,
        type: "standard",
        category: clip.category,
        subcategory: clip.subcategory,
        speaker_id: clip.speaker_id,
        has_potential: true, // not sure if we should copy `clip.has_potential` or force it to be `true`; choosing the latter for now.
        from_clipper: true, // assuming the currently-signed-in user is an admin of some sort
        is_reviewed: clip.is_reviewed,
        time_in: clip.time_in,
        duration: clip.duration,
        reviewer_user_id: clip.reviewer_user_id,
        user_id: authUser.id,
        source_clip_id: clip.id,
      },
    });
    notify(
      <Paper elevation={6} sx={{ p: 3, bgcolor: "#4bb543" }}>
        Clip Cloned <a href={`/#/clips_new/${newClip.data.id}`}>Open</a>
      </Paper>,
    );
  };
  return (
    <IconButton
      variant="contained"
      color="default"
      size="small"
      onClick={handleCloneClick}
    >
      <CopyAll />
    </IconButton>
  );
};

/** Custom Input which shows/hides the `has_potential` filter based on the `from_clipper` filter */
function FromClipperInput() {
  const { filterValues, setFilters, hideFilter, showFilter } = useListContext();
  const fromClipper = filterValues.from_clipper;

  // biome-ignore lint/correctness/useExhaustiveDependencies: false
  useEffect(() => {
    if (fromClipper === true) {
      hideFilter("has_potential");
    } else if (fromClipper === false) {
      showFilter("has_potential", false);
    }
    // es-lint-ignore-next-line
  }, [fromClipper, setFilters]);

  return (
    <BooleanInput
      source="from_clipper"
      key="from_clipper"
      label="From Clipper?"
    />
  );
}

const filters = [
  // <TextInput source="id" key="id" />,
  <SelectArrayInput
    style={{ width: 200 }}
    label="Clip Type"
    source="type"
    key="type"
    choices={[
      { id: "dose", name: "Dose" },
      { id: "tat_clip", name: "TAT Clip" },
      { id: "plug", name: "Plug" },
      { id: "pending", name: "Pending" },
      { id: "standard", name: "Standard" },
    ]}
    alwaysOn
  />,
  <TextInput source="name" key="name" label="Title" alwaysOn />,
  <SpeakerInput
    label="Speaker"
    source="speaker_id"
    key="speaker_id"
    reference="speakers"
    alwaysOn
  />,
  <ReferenceInput source="user_id" key="user_id" reference="users" alwaysOn>
    <AutocompleteInput
      label="User Email"
      filterToQuery={(inputValue) => ({ email: inputValue })}
    />
  </ReferenceInput>,
  <BooleanInput source="is_reviewed" key="is_reviewed" label="Is Reviewed?" />,
  <FromClipperInput
    source="from_clipper"
    key="from_clipper"
    label="From Clipper?"
    alwaysOn
  />,
  <BooleanInput
    source="has_potential"
    key="has_potential"
    label="Has Potential?"
  />,
  <NumberInput
    source="lecture_id"
    key="lecture_id"
    label="Parent Lecture ID"
    alwaysOn
  />,
  <TextInput source="notes" key="notes" label="Notes" alwaysOn />,
  <DateInput
    label="From"
    key="created_at.>"
    source="created_at.>"
    alwaysOn
    // format={formatDateTimeInput}
    // parse={parseDateTimeIntUTC}
  />,
  <DateInput
    label="To"
    key="created_at.<"
    source="created_at.<"
    alwaysOn
    // format={formatDateTimeInput}
    // parse={parseDateTimeIntUTC}
  />,
];

function ListView(props) {
  return (
    <List
      {...props}
      actions={<CardListActions />}
      exporter={true}
      filters={filters}
      perPage={50}
      pagination={<MorePagination />}
      sort={{
        field: "created_at",
        order: "DESC",
      }}
      title="Clips"
    >
      <Datagrid rowClick={false}>
        <EditButton />
        <TextField source="id" />
        {/* <ImageField source="thumbnail" label="Thumbnail" /> */}
        <ReferenceField
          source="speaker_id"
          label="Speaker"
          reference="speakers"
        />
        <FunctionField
          label="Watch"
          render={(record) => (
            <a
              rel="noopener noreferrer"
              target="_blank"
              href={`https://clips.torahanytime.com/clips/${record.uuid}`}
            >
              <Launch />
            </a>
          )}
        />
        <CloneField label="Clone" />
        <WorthinessOrActionsField label="Worthiness" />
        <PublishingStatusOrActionsField label="Publish" />
        {/* <FunctionField
          label="Publish"
          render={(record) => (
            <PublishingStatusOrActionsField record={record} />
          )}
        /> */}
        <TextField source="name" label="Title" />
        {/* <TextField source="duration" label="Length" /> */}
        <FunctionField
          label="Length"
          render={(record) => getDurationStringShort(record.duration)}
        />
        <DateTimeField source="created_at" style={{ minWidth: 150 }} />
        <FunctionField
          label="Clipped By"
          render={(clip) => (
            <>
              <ReferenceField
                source="user_id"
                label="Clipped by"
                reference="users"
              />
              <WithListContext
                render={({ setFilters }) => (
                  <IconButton
                    variant="contained"
                    size="small"
                    color="default"
                    onClick={() => {
                      setFilters({ user_id: clip.user_id });
                    }}
                  >
                    <Filter />
                  </IconButton>
                )}
              />
            </>
          )}
        />
        <DownloadMp4Button label="Get mp4 Link" />
        <HasPotentialSwitch label="Has Potential?" />
        <ReviewedSwitch label="Reviewed?" />
        {/* <ReferenceField
          source="reviewer_user_id"
          label="Reviewed by"
          reference="users"
        /> */}
      </Datagrid>
    </List>
  );
}

export const Clips = {
  category: "database",
  name: "clips_new",
  list: ListView,
  create: CreateView,
  icon: ScissorsSquare,
  options: { label: "Clips (New Interface)" },
  edit: EditView,
};
