import { CopyToClipboard } from "../../components";
import { useDedicationsText } from "./hooks";

export function InstagramTemplate(props) {
  const {
    record: {
      dose_number,
      speaker_title_short,
      speaker_name_first,
      speaker_name_last,
      lecture_id,
      title,
      speaker_id,
      double_dose,
      released_at,
    },
  } = props;

  const { dedications, isLoading: isDedicationTextLoading } =
    useDedicationsText(released_at, double_dose);

  if (isDedicationTextLoading) {
    return <div>Loading...</div>;
  }

  const speakerFullName = `${speaker_title_short} ${speaker_name_first} ${speaker_name_last}`;

  const textContent = `
⭐ ${title}
👤 By ${speakerFullName}
💊 Daily Dose ${dose_number?.toLocaleString("en")}
  
Full TorahAnytime Lecture
🎥 Video MyTAT.me/v${lecture_id}
🎙 Audio MyTAT.me/a${lecture_id}
👀 More videos by ${speakerFullName} MyTAT.me/s${speaker_id}

Leave a comment and let us know how this Dose impacted you.

Want to sponsor a future Dose? Click Here: TorahAnytime.com/dose-sponsor

Get notified when a new Dose is added. Simply Follow @torahanytime

💫 Thank You to the Sponsors of Today's Daily Dose:
${dedications.replaceAll("*", "")}

#torah #torahstudy #torahlife #jewish
`.trim();

  return (
    <div style={{ whiteSpace: "pre-line" }}>
      <div>
        {textContent}

        <CopyToClipboard textToCopy={textContent} />
      </div>
    </div>
  );
}
