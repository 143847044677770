// import { Infinity } from "lucide-react";
import resourceIcon from "@mui/icons-material/SwapHoriz";
import {
  Create,
  Datagrid,
  DateField,
  DateInput,
  Edit,
  List,
  useInput,
  required,
  NumberField,
  SimpleForm,
  TextField,
  useRecordContext,
  RadioButtonGroupInput,
  TextInput,
} from "react-admin";
import { useController } from "react-hook-form";
import { CardListActions, EditorToolbar, validation } from "../../components";
import DatePickerInputViewer from "../../components/input/DatePickerInputViewer";
import HebrewDatepickerInput from "../../components/input/HebrewDatepickerInput";
import AudioRecorderInput from "../../components/input/AudioRecorderInput";
import DeleteIcon from "@mui/icons-material/Delete";
import Icon from "@mui/material/Icon";
import { useState } from "react";

const filters = [
  <NumberField source="id" label="Id" alwaysOn key="id" />,
  <DateInput source="date" label="Dedication Date" alwaysOn key="date" />,
];
const PageDescription = () => (
  <div className="page-description">
    <h1>Sponsored Dedications</h1>
    <p>
      This page displays list of all sponsored dedications that appear before videos on the
      platform. Each dedication includes details like the sponsor's name and the
      dedication date.
    </p>
  </div>
);
const AddEditPageDescription = () => (
  <div className="page-description">
    <h1>Add or Edit Sponsored Dedications</h1>
    <p>
      Use this page to create a new dedication or update an existing one.
      Provide the required details, such as sponsor name, dedication date, and
      sponsor relationship with recording, to ensure the dedication is displayed
      accurately before videos.
    </p>
  </div>
);
function ListView(props) {
  const DateRendorField = ({ source }) => {
    const record = useRecordContext(); // Get current record context
    if (!record) return null;

    // Extract the date and format it
    const dateSource = record[source];
    let dateString = dateSource.replace("T00:00:00.000Z", "");
    const [year, month, day] = dateString.split("-");
    return `${month}/${day}/${year}`;
  };
  return (
    <>
      <PageDescription />
      <List
        {...props}
        actions={<CardListActions />}
        exporter={true}
        filters={filters}
        perPage={50}
        sort={{ field: "created_at", order: "DESC" }}
        title="Sponsor Dedications"
      >
        <Datagrid rowClick="edit">
          <TextField
            source="id"
            label="ID"
            validate={[validation.required()]}
          />
          <TextField source="sponsor_name" label="Sponsor Name" />
          <TextField
            source="sponsor_relationship"
            label="Sponsor Relationship"
          />
          <DateRendorField source="date" label="Dedication Date" />
          <TextField source="hebrew_date" label="Hebrew Day" />
          <TextField source="hebrew_month" label="Hebrew Month" />
          <TextField source="hebrew_year" label="Hebrew Year" />
        </Datagrid>
      </List>
    </>
  );
}
const SponsorNameField = () => {
  const { field: dedicationBy } = useController({
    name: "anonymous",
    defaultValue: "",
  });
  const { field: dedicationSponsor } = useController({
    name: "sponsor_name",
    defaultValue: "",
  });
  return (
    dedicationBy &&
    dedicationBy.value == 1 && (
      <TextInput source="sponsor_name" label="Sponsor Name" />
    )
  );
};
const DedicationRecordingField = () => {
  const record = useRecordContext();
  const [showRecording, setShowRecording] = useState(true);
  const {
    field,
    formState: { isSubmitting },
  } = useInput({ source: "audio_url" });

  return record?.audio_path && showRecording ? (
    <>
      <h4>Dedication Recording</h4>
      {/* biome-ignore lint/a11y/useMediaCaption: we don't have captions for on-the-fly recordings */}
      <div className="recorder-item d-flex justify-center items-center">
        <audio
          id="audioPlayback-list"
          src={record.audio_url + `?v=` + Date.now()}
          controls
          preload="auto"
        />
        <Icon
          component={DeleteIcon}
          onClick={() => {
            setShowRecording(false);
            field.onChange("");
          }}
          sx={{
            color: "red",
            cursor: "pointer",
          }}
        />
        <br />
      </div>
    </>
  ) : null;
};

const RenderEditForm = (redirect) => {
  return (
    <SimpleForm redirect={redirect} toolbar={<EditorToolbar />}>
      <AddEditPageDescription />
      <h4>Dedication Date</h4>
      <HebrewDatepickerInput source="date" />
      <h4>Dedication by</h4>
      <RadioButtonGroupInput
        source="anonymous"
        label=""
        choices={[
          { id: 1, name: "Sponsor Name" },
          { id: 2, name: "Anonymous" },
          { id: 3, name: "Not to show" },
        ]}
        validate={[required()]}
      />
      <SponsorNameField />
      <h4>Dedication to</h4>
      <TextInput
        source="sponsor_relationship"
        label="Sponsor Relationship"
        validate={[validation.required()]}
      />
      <DedicationRecordingField />
      <AudioRecorderInput source="audio" />
    </SimpleForm>
  );
};

function EditView(props) {
  return (
    <Edit {...props} undoable={false}>
      {RenderEditForm({ redirect: false })}
    </Edit>
  );
}
function CreateView(props) {
  return <Create {...props}>{RenderEditForm({ redirect: "edit" })}</Create>;
}
export const SponsorDedications = {
  category: "database",
  name: "sponsor_dedications",
  icon: resourceIcon,
  list: ListView,
  edit: EditView,
  create: CreateView,
  options: { label: "Sponsor Dedications" },
};
