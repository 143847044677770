import React, { ReactNode, useState, useEffect } from "react";
import { SortableContainer } from "react-sortable-hoc";
import { Button, Card, CardContent, Typography } from "@mui/material";
//@ts-ignore
import SortableItem from "./SortableItem.tsx";
import { Title, useNotify } from "react-admin";
import MuiToolbar from "@mui/material/Toolbar";
import { SortItem } from "../../../types/sortable.js";
import "./styles.scss";

type SortablePageProps = {
  title: string;
  info?: string;
  items: SortItem[];
  setItems: (items: SortItem[]) => void;
  renderItem: (
    item: SortItem,
    rowIndex: number,
    onChange: (rowIndex: number, field: string, value?: any) => void,
  ) => ReactNode;
  staticList?: boolean;
  renderFields?: () => ReactNode;
  handleSave: () => Promise<any>;
  createItem: () => SortItem;
  maxItems?: number;
  onSortSave?: boolean;
};

const SortablePage = (props: SortablePageProps) => {
  const {
    title,
    info,
    maxItems,
    renderFields,
    items,
    setItems,
    renderItem,
    createItem,
    handleSave,
    staticList,
    onSortSave,
  } = props;
  const notify = useNotify();
  const [changed, setChanged] = useState(false);
  useEffect(() => {
    if (changed && onSortSave) {
      handleSave()
        .then(() => notify("Success"))
        .catch((error) => notify(error.body.message, { type: "error" }));
    }
  }, [items]);
  const onChange = (index: number, field: string, value: any) => {
    const itemsCopy = [...items];
    itemsCopy[index] = { ...itemsCopy[index], [field]: value };
    setItems(itemsCopy);
    setChanged(true);
  };

  const addRow = () => {
    if (staticList) return;
    setItems([...items, createItem()]);
    setChanged(true);
  };

  const clearRow = (index: number) => {
    if (staticList) return;
    setItems(items.map((item, i) => (i === index ? createItem() : item)));
    setChanged(true);
  };

  const deleteRow = (index: number) => {
    if (staticList) return;
    if (!window.confirm("Are you sure?")) {
      return;
    }
    setItems(items.filter((item, i) => i !== index));
    setChanged(true);
  };

  const handleSortEnd = async ({ oldIndex, newIndex }) => {
    if (oldIndex === newIndex) return;
    const newItems = [...items];
    const element = newItems[oldIndex];
    newItems.splice(oldIndex, 1);
    newItems.splice(newIndex, 0, element);
    setItems(newItems);
    setChanged(true);
  };

  const onSave = async () => {
    if (changed) {
      handleSave()
        .then(() => notify("Success"))
        .catch((error) => notify(error.body.message, { type: "error" }));
    }
  };
  return (
    <>
      <Card>
        <Title title={title} />
        <CardContent>
          <div className="flex justify-between items-center">
            {!staticList && (!maxItems || maxItems > 1) && (
              <Button variant="contained" size="small" onClick={() => addRow()}>
                Add
              </Button>
            )}
            {info && (
              <Typography sx={{ mx: 2 }} variant="caption">
                {info}
              </Typography>
            )}
            {!onSortSave && (
              <Button
                variant="contained"
                size="small"
                onClick={() => onSave()}
                disabled={!changed}
              >
                Save
              </Button>
            )}
          </div>
          <SortableList
            items={items}
            actions={
              staticList
                ? { onChange }
                : { addRow, clearRow, deleteRow, onChange }
            }
            onSortEnd={handleSortEnd}
            hideSortableGhost={true}
            useDragHandle={true}
            maxItems={maxItems}
            renderItem={renderItem}
          />
          {renderFields && <div>{renderFields()}</div>}
          {!onSortSave && (
            <MuiToolbar role="toolbar">
              <Button
                variant="contained"
                size="small"
                onClick={() => onSave()}
                disabled={!changed}
              >
                Save
              </Button>
            </MuiToolbar>
          )}
        </CardContent>
      </Card>
    </>
  );
};

const SortableList = SortableContainer(
  ({ items, actions, maxItems, renderItem }) => {
    return (
      <ul className="sortableList">
        {items &&
          items.length > 0 &&
          items.map((item: SortItem, index: number) => (
            <SortableItem
              item={item}
              key={`item-${index}`}
              index={index}
              actions={actions}
              maxItems={maxItems}
              rowIndex={index}
              renderItem={renderItem}
            />
          ))}
      </ul>
    );
  },
);

export default SortablePage;
