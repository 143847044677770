import { UserCircle } from "lucide-react";
import { RichTextInput } from "ra-input-rich-text";
import {
  BooleanField,
  BooleanInput,
  Button,
  Create,
  Datagrid,
  Edit,
  FileField,
  FileInput,
  FormDataConsumer,
  FormTab,
  FunctionField,
  ImageField,
  ImageInput,
  Labeled,
  List,
  NumberInput,
  ReferenceField,
  SelectInput,
  TabbedForm,
  TextField,
  TextInput,
  UrlField,
  TopToolbar,
  FilterButton,
  CreateButton,
} from "react-admin";
import {
  CardListActions,
  CopyToClipboard,
  EditorToolbar,
  OrganizationInput,
  SpeakerTitleInput,
  validation,
} from "../../components";
import { booleanToInteger, integerToBoolean } from "../../components/utils";
import { apiURL, authToken } from "../../server";
import { SpeakerRethink } from "./SpeakerRethink";
import { DownloadExportButton2 } from "../../components/list/DownloadExportButton";

function followersExporter(speakerId) {
  return (e) => {
    e.preventDefault();
    // window.alert(`Exporting followers of speaker id ${speakerId}...`);
    const token = authToken();
    const url = apiURL(
      `/admin/speakers/${speakerId}/followers/export?` +
        `ua_token=${encodeURIComponent(token)}`,
    );
    // NOTE: The export endpoint MUST set the Content-Disposition, e.g.:
    // .header("Content-Disposition", `attachment;filename=${filename}`)
    // in order to prompt the browser to start downloading the file.
    window.location = url;
  };
}

const EditActions = (props) => {
  const { data: { id, follower_count = 0 } = {} } = props;
  return (
    <CardListActions>
      <Button
        color="primary"
        onClick={followersExporter(id)}
        label={`Export Followers (${follower_count}) Emails`}
        title="Exports the emails of all followers..."
      />
    </CardListActions>
  );
};

function renderEditForm() {
  return (
    <TabbedForm toolbar={<EditorToolbar />}>
      <FormTab label="Summary" replace>
        <ImageField source="photo_url" label="Photo" />
        <Labeled>
          <TextField source="follower_count" />
        </Labeled>
        <SpeakerTitleInput />
        <TextInput
          source="name_first"
          label="First name"
          validate={[validation.required()]}
        />
        <TextInput
          source="name_last"
          label="Last name"
          validate={[validation.required()]}
        />
        <TextInput label="Donate Link" source="donate_link" allowEmpty />
        <TextInput label="Ad Link" source="ads_link" allowEmpty />
        <NumberInput label="Causematch ID" source="causematch_id" allowEmpty />
        <OrganizationInput allowEmpty />
        <TextInput source="photo" />
        <TextInput source="speaker_audio_url" allowEmpty />
        <Labeled>
          <FunctionField
            source="short_link"
            label="Shortlink"
            style={{ width: "inherit" }}
            render={(record) => {
              return (
                <div style={{ display: "flex" }}>
                  <UrlField
                    record={record}
                    source="short_link"
                    label="Shortlink"
                    target="_blank"
                    rel="noopener noreferrer"
                  />
                  <CopyToClipboard textToCopy={record.short_link} />
                </div>
              );
            }}
          />
        </Labeled>
        <FileInput
          source="FILE_speaker_audio_url"
          label="Audio Upload For Xchange Telecom"
          placeholder={<p>Click or drop an audio here.</p>}
        >
          <FileField source="src" title="title" />
        </FileInput>
        <ImageInput
          source="FILE_photo"
          label="Photo Upload"
          accept="image/*"
          placeholder={<p>Click or drop a photo here.</p>}
        >
          <ImageField source="src" title="title" />
        </ImageInput>
        <BooleanInput source="display_active" label="Display" />
        <BooleanInput source="female" label="Female" />
        <BooleanInput source="is_guest" label="Guest Speaker" />
        <BooleanInput source="login_access" label="Only viewable after login" />
        <BooleanInput source="no_download" label="Disable downloads" />
        <BooleanInput
          source="should_new_lectures_be_on_recents_by_default"
          label="Should New Lectures Be on Recents By Default"
        />
        <BooleanInput
          source="should_new_lectures_trigger_notifications_by_default"
          label="Should New Lectures Trigger Notifications By Default"
        />
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            !formData.female ? null : (
              <SelectInput
                source="view_female_level"
                label="View Female Level"
                choices={[
                  {
                    id: 1,
                    name: "Default (No image. Audio only for male viewers)",
                  },
                  { id: 2, name: "Invisible to male viewers" },
                ]}
                {...rest}
              />
            )
          }
        </FormDataConsumer>
        <Labeled>
          <TextField source="dial_in_ext" label="Dial-in extension" />
        </Labeled>
        <BooleanInput
          source="display_dial_in_ext"
          label="Display dial-in extension"
        />
        <BooleanInput source="ya_approved" label="YeshivaAnytime approved" />
      </FormTab>
      <FormTab label="Description" replace>
        <RichTextInput source="desc" addLabel={false} />
      </FormTab>

      <FormTab label="Dose" replace>
        <ImageField source="dose_email_photo_url" label=" Dose Email Photo" />
        <ImageInput
          source="FILE_dose_email_photo"
          label="Upload Email Photo"
          accept="image/*"
          placeholder={<p>Click or drop a photo here.</p>}
        >
          <ImageField source="src" title="title" />
        </ImageInput>
        <ImageField
          source="double_dose_email_photo_url"
          label="Double Dose Email Photo"
        />
        <ImageInput
          source="FILE_double_dose_email_photo"
          label="Upload Double Dose Email Photo"
          accept="image/*"
          placeholder={<p>Click or drop a photo here.</p>}
        >
          <ImageField source="src" title="title" />
        </ImageInput>
        <ImageField
          source="dose_podcast_photo_url"
          label=" Dose Podcast Photo"
        />
        <ImageInput
          source="FILE_dose_podcast_photo"
          label="Upload Podcast Photo"
          accept="image/*"
          placeholder={<p>Click or drop a photo here.</p>}
        >
          <ImageField source="src" title="title" />
        </ImageInput>
        <ImageField
          source="dose_instagram_photo_url"
          label=" Dose Instagram Photo"
        />

        <ImageInput
          source="FILE_dose_instagram_photo"
          label="Upload Instagram Photo"
          accept="image/*"
          placeholder={<p>Click or drop a photo here.</p>}
        >
          <ImageField source="src" title="title" />
        </ImageInput>
        <ImageField source="dose_app_photo_url" label=" Dose App Photo" />
        <ImageInput
          source="FILE_dose_app_photo"
          label="Upload App Photo"
          accept="image/*"
          placeholder={<p>Click or drop a photo here.</p>}
        >
          <ImageField source="src" title="title" />
        </ImageInput>
      </FormTab>

      <FormTab label="Lecture promo" replace>
        <RichTextInput source="lecture_promo" addLabel={false} />
      </FormTab>
      <FormTab label="Rethink Database" replace>
        <SpeakerRethink />
      </FormTab>
    </TabbedForm>
  );
}

function CreateView(props) {
  return (
    <Create {...props} redirect="edit">
      {renderEditForm()}
    </Create>
  );
}

function EditView(props) {
  return (
    <Edit
      {...props}
      redirect={false}
      undoable={false}
      actions={<EditActions />}
    >
      {renderEditForm()}
    </Edit>
  );
}

const ListActions = () => {
  return (
    <TopToolbar>
      <FilterButton />
      <CreateButton />
      <DownloadExportButton2 />
    </TopToolbar>
  );
};

function ListView(props) {
  return (
    <List
      {...props}
      actions={<ListActions />}
      exporter={true}
      filterDefaultValues={{ display_active: 1 }}
      filters={[
        <BooleanInput
          label="Active"
          source="display_active"
          key="display_active"
          alwaysOn
          parse={booleanToInteger}
          format={integerToBoolean}
        />,
        <BooleanInput
          label="YA Approved"
          source="ya_approved"
          key="ya_approved"
        />,
        <TextInput source="id" key="id" alwaysOn />,
        <TextInput
          label="First Name"
          source="name_first"
          key="name_first"
          alwaysOn
        />,
        <TextInput
          label="Last Name"
          source="name_last"
          key="name_last"
          alwaysOn
        />,
        <OrganizationInput
          label="Organization"
          source="org"
          key="org"
          reference="organizations"
          alwaysOn
          allowEmpty
        />,
      ]}
      title="Speakers"
    >
      <Datagrid rowClick="edit" bulkActionButtons={false}>
        <TextField source="id" label="Id" />
        <ImageField source="photo_url" label="Photo" />
        <ReferenceField
          label="Title"
          source="title"
          reference="speaker-titles"
          linkType={false}
          link={false}
          allowEmpty={true}
        >
          <TextField source="title" />
        </ReferenceField>
        <TextField source="name_first" label="First name" />
        <TextField source="name_last" label="Last name" />
        {/* <NumberField source="follower_count" label="Followers" /> */}
        <BooleanField source="login_access" label="Login access" />
        <BooleanField source="display_active" label="Active" />
        <BooleanField source="female" label="Female" />
        <BooleanField source="is_guest" label="Guest" />
        <BooleanField source="no_download" label="No download" />
        <BooleanField source="ya_approved" label="YA Approved" />
        <ReferenceField
          label="Organization"
          source="org"
          reference="organizations"
          linkType={false}
          allowEmpty={true}
        >
          <TextField source="name" />
        </ReferenceField>
        {/* <LongTextField source="lecture_promo" /> */}
        {/* <RichTextField source="desc" /> */}
      </Datagrid>
    </List>
  );
}

export const Speakers = {
  category: "database",
  name: "speakers",
  create: CreateView,
  edit: EditView,
  icon: UserCircle,
  list: ListView,
  recordRepresentation: (record) => `${record.name_first} ${record.name_last}`,
};
