/**
 * @file Copied from react-admin and modified to download a file.
 * Original code: packages/ra-ui-materialui/src/button/ExportButton.js
 */
import GetApp from "@mui/icons-material/GetApp";
import PropTypes from "prop-types";
import qs from "query-string";
import { Component } from "react";
import { Button, fetchUtils, useResourceContext } from "react-admin";

import { apiURL, authToken } from "../../server";

const sanitizeRestProps = ({
  basePath,
  filter,
  maxResults,
  resource,
  sort,
  ...rest
}) => rest;

export const DownloadExportButton2 = (props) => {
  const { sort, filter, label = "Ekshport", icon = <GetApp /> } = props;
  const resource = useResourceContext();

  const handleClick = () => {
    const params = {
      ...fetchUtils.flattenObject(filter),
      _sort: sort?.field || "id",
      _order: sort?.order || "asc",
    };
    const token = authToken();
    const url = apiURL(
      `/admin/${resource}/export?ua_token=${encodeURIComponent(token)}&${qs.stringify(params)}`,
    );
    // NOTE: The export endpoint MUST set the Content-Disposition, e.g.:
    // .header("Content-Disposition", `attachment;filename=${filename}`)
    // in order to prompt the browser to start downloading the file.
    window.location = url;
  };

  return (
    <Button onClick={handleClick} label={label} {...sanitizeRestProps(props)}>
      {icon}
    </Button>
  );
};

export class DownloadExportButton extends Component {
  static propTypes = {
    basePath: PropTypes.string,
    filter: PropTypes.object,
    label: PropTypes.string,
    resource: PropTypes.string.isRequired,
    sort: PropTypes.object,
    icon: PropTypes.element,
  };

  static defaultProps = {
    label: "ra.action.export",
    icon: <GetApp />,
  };

  handleClick = () => {
    const { filter, sort, resource } = this.props;
    // console.log("EXPORTING: ", {
    //   filter,
    //   sort,
    //   resource,
    // });
    const params = {
      ...fetchUtils.flattenObject(filter),
      _sort: sort?.field || "id",
      _order: sort?.order || "asc",
    };
    const token = authToken();
    const url = apiURL(
      `/admin/${resource}/export?ua_token=${encodeURIComponent(token)}&${qs.stringify(params)}`,
    );
    // NOTE: The export endpoint MUST set the Content-Disposition, e.g.:
    // .header("Content-Disposition", `attachment;filename=${filename}`)
    // in order to prompt the browser to start downloading the file.
    window.location = url;
  };

  render() {
    const { label, icon, ...rest } = this.props;

    return (
      <Button
        onClick={this.handleClick}
        label={label}
        {...sanitizeRestProps(rest)}
      >
        {icon}
      </Button>
    );
  }
}
