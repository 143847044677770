import React, { useEffect, useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import TextField from "@mui/material/TextField";
import { authClient } from "../../../server";
//@ts-ignore
import SortablePage from "../../../components/page/SortableFormPage.tsx";
import { PopupBanner, SortItem } from "../../../../types/sortable";
import { AlertCircle } from "lucide-react";
export const ManagePopupList = () => {
  const [popupBanners, setPopupBanners] = useState<SortItem[]>([]);

  useEffect(() => {
    getPopupItems().then((res) => {
      let banners = res?.json?.items;
      if (banners && banners.length > 0) {
        setPopupBanners(
          banners.map((item: any) => {
            return {
              ...item,
              display: item.display ? true : false,
              interval: item.interval ? item.interval : 0,
            };
          }),
        );
      }
    });
  }, []);

  const createItem = () => {
    return {
      display: true,
      image: "",
      title: "",
      link: "",
      onclick: () => {},
      height: 0,
      interval: 0,
      width: 0,
    };
  };

  const getPopupItems = async () => {
    return authClient.get("/admin/manage-banners/popup");
  };

  const handleSave = async () => {
    return authClient.put("/admin/manage-banners/popup", {popupBanners});
  };

  return (
    <SortablePage
      title="Popup Banners"
      info="NOTE: Checks are triggered on navigation only."
      items={popupBanners}
      setItems={setPopupBanners}
      renderItem={(item, rowIndex, onChange) => (
        <PopupItem item={item} rowIndex={rowIndex} onChange={onChange} />
      )}
      renderFields={() => {}}
      handleSave={handleSave}
      createItem={createItem}
      maxItems={1}
    />
  );
};

type PopupItemProps = {
  item: SortItem;
  rowIndex: number;
  onChange: (rowIndex: number, field: string, value?: any) => void;
};

const PopupItem = ({ item, rowIndex, onChange }: PopupItemProps) => {
  return (
    <div>
      <FormControl>
        <FormControlLabel
          label="Display"
          control={
            <Checkbox
              checked={!!item.display}
              onChange={(e) =>
                onChange(rowIndex, "display", e.currentTarget.checked)
              }
            />
          }
        />
      </FormControl>
      <TextField
        fullWidth
        label=""
        placeholder="Image URL"
        value={item.image}
        onChange={(e) =>
          onChange(rowIndex, "image", e.currentTarget.value || "")
        }
      />
      <TextField
        fullWidth
        label=""
        placeholder="Title"
        value={item.title}
        onChange={(e) =>
          onChange(rowIndex, "title", e.currentTarget.value || "")
        }
      />
      <TextField
        fullWidth
        label=""
        placeholder="Link URL"
        value={item.link}
        onChange={(e) =>
          onChange(rowIndex, "link", e.currentTarget.value || "")
        } 
      />
      <TextField
        fullWidth
        label=""
        placeholder="JS onclick function()"
        value={item.onclick}
        onChange={(e) =>
          onChange(rowIndex, "onclick", e.currentTarget.value || "")
        }
      />
      <TextField
        fullWidth
        label="Height"
        value={item.height}
        onChange={(e) =>
          onChange(rowIndex, "height", parseInt(e.currentTarget.value,10) || 0)
        }
        type="number"
      />
      <TextField
        fullWidth
        label="Width"
        value={item.width}
        onChange={(e) =>
          onChange(rowIndex, "width", parseInt(e.currentTarget.value,10) || 0)
        }
        type="number"
      />
      <TextField
        fullWidth
        label="Interval Between Popups (hour)"
        value={item.interval}
        onChange={(e) =>
          onChange(rowIndex, "interval", parseInt(e.currentTarget.value,10) || 0)
        }
        type="number"
      />
    </div>
  );
};

export const PopupList = {
  category: "banners",
  icon: AlertCircle,
  name: "popup-banners",
  url: "/manage-banners/popup",
  label: "Popup",
  exact: true,
  Element: <ManagePopupList />,
};
