import { CopyToClipboard } from "../../components";
import { useDedicationsText } from "./hooks";

export function WhatsAppTemplate(props) {
  const {
    record: {
      dose_number,
      speaker_title_short,
      speaker_name_first,
      speaker_name_last,
      lecture_id,
      title,
      speaker_id,
      double_dose,
      released_at,
    },
  } = props;

  const { dedications, isLoading: isDedicationTextLoading } =
    useDedicationsText(released_at, double_dose);

  if (isDedicationTextLoading) {
    return <div>Loading...</div>;
  }

  const speakerFullName = `${speaker_title_short} ${speaker_name_first} ${speaker_name_last}`;

  const doubleDoseText = `Enjoy This Torahanytime
*Erev Shabbat 🍞🍞 Double Dose*
*Double* the Time!
*Double* the Impact!
`;

  const textContent = `
  ${double_dose ? doubleDoseText : ""}
⭐ *${title}*
👤 By *${speakerFullName}*
💊 Daily Dose *#${dose_number?.toLocaleString("en")}*

*Get Your Feedback on Our Status to Help Inspire Others. Simply reply to the Dose and let us know how it impacted you.*

💫 *Thank You* to the Sponsors of Today's Daily Dose:
${dedications}

*Full TorahAnytime Lecture*
🎥 *Video* MyTAT.me/v${lecture_id}
🎙 *Audio* MyTAT.me/a${lecture_id}
👀 *More videos* by ${speakerFullName} MyTAT.me/s${speaker_id}

*Want to sponsor a future Dose?* 
Click Here: TorahAnytime.com/dose-sponsor

*Want Your Friends/Family to Receive the Daily Dose?* Simply Share This Link: https://wa.me/19293554268?text=Add%20Me

🤩 *Reply to the Dose and let us know how it impacted you.*
🗣 We Love Voice Notes`.trim();

  return (
    <div style={{ whiteSpace: "pre-line" }}>
      {textContent}

      <CopyToClipboard textToCopy={textContent} />
    </div>
  );
}
